import './Footer.css';

export const Footer = () => {
    return (
        <footer className="footer">
            <span>© 2023 Yacht Minsk </span>
            <span>УНП 192506260</span>
        </footer>
    );
}
